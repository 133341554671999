import {
    FETCH_ORDERS,
    FETCH_ORDERS_SUCCESS,
    FETCH_ORDERS_ERROR,
    UPDATE_ORDERS,
    UPDATE_ORDERS_SUCCESS,
    UPDATE_ORDERS_ERROR,
    ADD_ORDERS,
    ADD_ORDERS_SUCCESS,
    ADD_ORDERS_ERROR,
    DELETE_ORDERS,
    DELETE_ORDERS_SUCCESS,
    DELETE_ORDERS_ERROR,
    ORDERS_ERROR_REMOVE,
    ORDERS_UPDATE_COLUMNS,

    FETCH_CARDS,
    FETCH_CARDS_SUCCESS,
    FETCH_CARDS_ERROR,
    ADD_CARDS,
    ADD_CARDS_SUCCESS,
    ADD_CARDS_ERROR,
    CHARGE_CARDS,
    CHARGE_CARDS_SUCCESS,
    CHARGE_CARDS_ERROR,
    REFUND_CHARGE,
    REFUND_CHARGE_SUCCESS,
    REFUND_CHARGE_ERROR,
    TIMELINE,
    TIMELINE_ERROR,
    TIMELINE_SUCCESS,
    APPLYPROMOCODE_ORDERS_SERVICE,
    APPLYPROMOCODE_ORDERS_SERVICE_ERROR,
    APPLYPROMOCODE_ORDERS_SERVICE_SUCCESS,
    DELETE_ORDERS_SERVICE,
    DELETE_ORDERS_SERVICE_ERROR,
    DELETE_ORDERS_SERVICE_SUCCESS,
    APPLY_WALLETPOINT_ORDER,
    APPLY_WALLETPOINT_ORDER_ERROR,
    APPLY_WALLETPOINT_ORDER_SUCCESS,
    REMOVE_WALLETPOINT_ORDER,
    REMOVE_WALLETPOINT_ORDER_ERROR,
    REMOVE_WALLETPOINT_ORDER_SUCCESS,
    FETCH_CUSTOMER_WALLETS_POINTS,
    FETCH_CUSTOMER_WALLETS_POINTS_SUCCESS,
    FETCH_CUSTOMER_WALLETS_POINTS_ERROR,
    UPDATE_ORDERS_ADDRESS,
    UPDATE_ORDERS_ADDRESS_SUCCESS,
    UPDATE_ORDERS_ADDRESS_ERROR,
} from '@redux/types'

import _ from 'lodash'

const initialState = {
    type: null,
    error: null,
    list: {},
    card:{},
    wallet:{},
    sortBy: 'created_at',
    sortDir: -1,
    filters: {},
    columns: ["orderNumber", "created_at", "Services", "Customer", "Status"]
};

export default (state = initialState, action) => {
    let list;
    switch (action.type) {

        case FETCH_CARDS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_CARDS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_CARDS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        
            case ADD_CARDS:
                return {
                    ...state,
                    ...action,
                    error: null,
                }
                break;
            case ADD_CARDS_SUCCESS:
                return {
                    ...state,
                    ...action,
                    error: null,
                }
                break;
            case ADD_CARDS_ERROR:
                return {
                    ...state,
                    ...action
                }
                break;
        
                case TIMELINE:
                    return {
                        ...state,
                        ...action,
                        error: null,
                    }
                    break;
                case TIMELINE_SUCCESS:
                    return {
                        ...state,
                        ...action,
                        error: null,
                    }
                    break;
                case TIMELINE_ERROR:
                    return {
                        ...state,
                        ...action
                    }
                    break;

                    case APPLYPROMOCODE_ORDERS_SERVICE:
                    return {
                        ...state,
                        ...action,
                        error: null,
                    }
                    break;
                case APPLYPROMOCODE_ORDERS_SERVICE_SUCCESS:
                    return {
                        ...state,
                        ...action,
                        error: null,
                    }
                    break;
                case APPLYPROMOCODE_ORDERS_SERVICE_ERROR:
                    return {
                        ...state,
                        ...action
                    }
                    break;

                    case FETCH_CUSTOMER_WALLETS_POINTS:
                        return {
                            ...state,
                            ...action,
                            error: null,
                        }
                        break;
                    case FETCH_CUSTOMER_WALLETS_POINTS_SUCCESS:
                        return {
                            ...state,
                            ...action,
                            error: null,
                        }
                        break;
                    case FETCH_CUSTOMER_WALLETS_POINTS_ERROR:
                        return {
                            ...state,
                            ...action
                        }
                        break;      

                    case APPLY_WALLETPOINT_ORDER:
                        return {
                            ...state,
                            ...action,
                            error: null,
                        }
                        break;
                    case APPLY_WALLETPOINT_ORDER_SUCCESS:
                        return {
                            ...state,
                            ...action,
                            error: null,
                        }
                        break;
                    case APPLY_WALLETPOINT_ORDER_ERROR:
                        return {
                            ...state,
                            ...action
                        }
                        break;    

                        case REMOVE_WALLETPOINT_ORDER:
                            return {
                                ...state,
                                ...action,
                                error: null,
                            }
                            break;
                        case REMOVE_WALLETPOINT_ORDER_SUCCESS:
                            return {
                                ...state,
                                ...action,
                                error: null,
                            }
                            break;
                        case REMOVE_WALLETPOINT_ORDER_ERROR:
                            return {
                                ...state,
                                ...action
                            }
                            break;          

                case CHARGE_CARDS:
                    return {
                        ...state,
                        ...action,
                        error: null,
                    }
                    break;
                case CHARGE_CARDS_SUCCESS:
                    return {
                        ...state,
                        ...action,
                        error: null,
                    }
                    break;
                case CHARGE_CARDS_ERROR:
                    return {
                        ...state,
                        ...action
                    }
                    break;        
                    case REFUND_CHARGE:
                        return {
                            ...state,
                            ...action,
                            error: null,
                        }
                        break;
                    case REFUND_CHARGE_SUCCESS:
                        return {
                            ...state,
                            ...action,
                            error: null,
                        }
                        break;
                    case REFUND_CHARGE_ERROR:
                        return {
                            ...state,
                            ...action
                        }
                        break;

                        case DELETE_ORDERS_SERVICE:
                            return {
                                ...state,
                                ...action,
                                error: null,
                            }
                            break;
                        case DELETE_ORDERS_SERVICE_SUCCESS:
                
                            return {
                                ...state,
                            ...action,
                            error: null,
                            }
                            break;
                        case DELETE_ORDERS_SERVICE_ERROR:
                            return {
                                ...state,
                                ...action,
                            }
                            break;    

        case FETCH_ORDERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_ORDERS_SUCCESS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case FETCH_ORDERS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        
            case UPDATE_ORDERS_ADDRESS:
                return {
                    ...state,
                    ...action,
                    error: null,
                }
                break;
            case UPDATE_ORDERS_ADDRESS_SUCCESS:
                return {
                    ...state,
                    ...action,
                    error: null,
                }
                break;
            case UPDATE_ORDERS_ADDRESS_ERROR:
                return {
                    ...state,
                    ...action
                }
                break;

        case UPDATE_ORDERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case UPDATE_ORDERS_SUCCESS:
            list = _.cloneDeep(state.list);
            let index = _.findIndex(list.data, (item) => {
                return item._id == action.payload._id
            })
            list.data[index] = action.payload
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case UPDATE_ORDERS_ERROR:
            return {
                ...state,
                ...action
            }
            break;
        case ADD_ORDERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case ADD_ORDERS_SUCCESS:
            list = _.cloneDeep(state.list);
            list.data.unshift(action.payload);
            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case ADD_ORDERS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case DELETE_ORDERS:
            return {
                ...state,
                ...action,
                error: null,
            }
            break;
        case DELETE_ORDERS_SUCCESS:
            list = _.cloneDeep(state.list);
            _.remove(list.data, (item) => {
                return item._id == action.payload._id
            })

            return {
                ...state,
                list: list,
                error: null,
            }
            break;
        case DELETE_ORDERS_ERROR:
            return {
                ...state,
                ...action,
            }
            break;
        case ORDERS_ERROR_REMOVE:
            return {
                ...state,
                ...action,
            }
            break;

        case ORDERS_UPDATE_COLUMNS:
            return {
                ...state,
                columns: action.payload
            }
        default:
            return state;
    }
};
